import React from 'react';

import { container, wrapper, thankYouBox } from './client-verification.module.scss';
import { TSectionSignInUp } from '../../templates/sign-in-up';
import useTranslations from '../../hooks/use-translations';

import ThankYouBox from '../molecules/thank-you-box';
import Section from '../hoc/section';

export interface IClientVerificationProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: TSectionSignInUp;
}

const ClientVerification: React.FC<IClientVerificationProps> = ({
    className = '',
    section,
    TitleTag,
}) => {
    const t = useTranslations('ClientVerification');

    return (
        <Section
            className={`${className} ${container}`}
            classes={{ container: wrapper }}
            TitleTag={TitleTag}
            style={section.style}
            roundedSection={true}
        >
            <ThankYouBox
                className={thankYouBox}
                data={{
                    title: `${t.title}`,
                    description: `${t.description}`,
                    button: {
                        url: '/',
                        text: `${t.back}`,
                    },
                }}
            />
        </Section>
    );
};

export default ClientVerification;
