import React from 'react';

import { wrapper, description, link, actions } from './thank-you-box.module.scss';

import Button from '../atoms/button';
import Markdown from '../hoc/markdown';
import Title from '../atoms/title';

interface IThankYouBoxButton {
    url?: string | undefined;
    text: string | undefined;
}

interface ThankYouBoxProps {
    className?: string;
    data: {
        title: string;
        description: string;
        button?: IThankYouBoxButton | null;
        buttons?: IThankYouBoxButton[];
    };
}

const ThankYouBox: React.FC<ThankYouBoxProps> = ({ className, data }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <Title>{data.title}</Title>
            <Markdown className={description}>{data.description}</Markdown>
            {data.button?.url && data.button?.text && (
                <Button className={link} to={data.button.url} as={'link'}>
                    {data.button.text}
                </Button>
            )}
            {data.buttons && data.buttons.length > 0 && (
                <div className={actions}>
                    {data.buttons.map((button) => {
                        if (!button.url || !button.text) return null;
                        return (
                            <Button className={link} to={button.url} as={'link'}>
                                {button.text}
                            </Button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ThankYouBox;
